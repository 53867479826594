define("@frontile/core/components/collapsible/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/test-waiters", "@frontile/core/utils/safe-styles"], function (_exports, _component, _templateFactory, _component2, _object, _testWaiters, _safeStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    style={{this.styles}}
    ...attributes
    {{did-update this.update @isOpen}}
    {{on "transitionend" this.onTransitionEnd}}
  >
    {{yield}}
  </div>
  */
  {
    "id": "8XCT+0HX",
    "block": "[[[11,0],[16,5,[30,0,[\"styles\"]]],[17,1],[4,[38,0],[[30,0,[\"update\"]],[30,2]],null],[4,[38,1],[\"transitionend\",[30,0,[\"onTransitionEnd\"]]],null],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@isOpen\",\"&default\"],false,[\"did-update\",\"on\",\"yield\"]]",
    "moduleName": "@frontile/core/components/collapsible/index.hbs",
    "isStrictMode": false
  });

  const waiter = (0, _testWaiters.buildWaiter)('@frontile/core:collapsible');
  let Collapsible = (_class = class Collapsible extends _component2.default {
    // Internal value to track if open or not
    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "isInitiallyOpen", false);

      _defineProperty(this, "waiterToken", void 0);

      _defineProperty(this, "isCurrentlyOpen", false);

      if (this.args.isOpen) {
        this.isInitiallyOpen = true;
        this.isCurrentlyOpen = true;
      }
    }

    get styles() {
      let styles = {};

      if (!this.isInitiallyOpen) {
        styles = { ...styles,
          height: this.args.initialHeight || 0,
          opacity: this.args.initialHeight ? '1' : '0'
        };
      }

      if (this.args.initialHeight || !this.isInitiallyOpen) {
        styles = { ...styles,
          overflow: 'hidden'
        };
      }

      return (0, _safeStyles.default)(styles);
    }

    update(element, _ref) {
      let [isOpen] = _ref;

      if (this.isCurrentlyOpen !== !!isOpen) {
        this.waiterToken = waiter.beginAsync();
      }

      if (isOpen) {
        this.expand(element);
      } else {
        this.contract(element);
      }
    }

    onTransitionEnd(event) {
      if (event.propertyName === 'height' && this.args.isOpen) {
        event.target.style.height = 'auto';
        event.target.style.overflow = '';
      }

      if (this.waiterToken) {
        // when is opened, wait for height transition to finish
        // when closed, wait for opacity transition to finish
        // when closed and has initialHeight, wait for height transition to finish
        if (this.args.isOpen && event.propertyName === 'height' || !this.args.isOpen && event.propertyName === 'opacity' || !this.args.isOpen && this.args.initialHeight && event.propertyName === 'height') {
          waiter.endAsync(this.waiterToken);
        }
      }
    }

    heightTransision(duration) {
      return `height ${duration}s cubic-bezier(0.4, 0, 0.2, 1) 0ms`;
    }

    opacityTransision(duration) {
      return `opacity ${duration}s ease-in-out 0ms`;
    }

    expand(element) {
      this.isCurrentlyOpen = true;
      element.style.transition = [this.heightTransision(0.4), this.opacityTransision(0.3)].join(', ');
      element.style.overflow = 'hidden';
      const height = element.scrollHeight;
      window.requestAnimationFrame(() => {
        element.style.opacity = '1';
        element.style.height = `${height}px`;
      });
    }

    contract(element) {
      this.isCurrentlyOpen = false;
      const height = element.scrollHeight;
      element.style.transition = '';
      element.style.overflow = 'hidden';
      window.requestAnimationFrame(() => {
        element.style.height = `${height}px`;
        element.style.transition = [this.heightTransision(0.2), this.opacityTransision(0.3)].join(', ');
        window.requestAnimationFrame(() => {
          if (!this.args.initialHeight) {
            element.style.opacity = '0';
          }

          element.style.height = this.args.initialHeight || '0';
        });
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTransitionEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTransitionEnd"), _class.prototype)), _class);
  _exports.default = Collapsible;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Collapsible);
});