define("@frontile/core/utils/safe-styles", ["exports", "@ember/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = safeStyles;

  function safeStyles(style) {
    if (!style) {
      return (0, _template.htmlSafe)('');
    }

    const styles = [];
    Object.keys(style).forEach(key => {
      styles.push(`${key}:${style[key]}`);
    });
    return (0, _template.htmlSafe)(styles.join(';'));
  }
});