define("@frontile/core/components/close-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    type="button"
    class={{use-frontile-class "close-button" (if @size @size "md") class=@class}}
    ...attributes
    {{on "click" this.handleClick}}
  >
    {{#let
      (use-frontile-class
        "close-button" (if @size @size "md") part="icon"
      ) as |iconClassName|
    }}
      {{#if (has-block)}}
        {{yield iconClassName}}
      {{else}}
        <svg
          class={{iconClassName}}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      {{/if}}
    {{/let}}
  
    <VisuallyHidden>
      {{if @title @title "Close"}}
    </VisuallyHidden>
  </button>
  */
  {
    "id": "DvWuCocY",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[28,[37,0],[\"close-button\",[52,[30,1],[30,1],\"md\"]],[[\"class\"],[[30,2]]]]],[17,3],[4,[38,2],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n\"],[44,[[28,[37,0],[\"close-button\",[52,[30,1],[30,1],\"md\"]],[[\"part\"],[\"icon\"]]]],[[[41,[48,[30,6]],[[[1,\"      \"],[18,6,[[30,4]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"svg\"],[15,0,[30,4]],[14,\"aria-hidden\",\"true\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"fill\",\"none\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"stroke\",\"currentColor\"],[12],[1,\"\\n        \"],[10,\"path\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"d\",\"M6 18L18 6M6 6l12 12\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[4]]],[1,\"\\n  \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[52,[30,5],[30,5],\"Close\"]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@size\",\"@class\",\"&attrs\",\"iconClassName\",\"@title\",\"&default\"],false,[\"use-frontile-class\",\"if\",\"on\",\"let\",\"has-block\",\"yield\",\"visually-hidden\"]]",
    "moduleName": "@frontile/core/components/close-button.hbs",
    "isStrictMode": false
  });

  let CloseButton = (_class = class CloseButton extends _component2.default {
    handleClick(event) {
      if (typeof this.args.onClick === 'function') {
        this.args.onClick(event);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype)), _class);
  _exports.default = CloseButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CloseButton);
});